// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-doula-services-js": () => import("./../src/pages/doula-services.js" /* webpackChunkName: "component---src-pages-doula-services-js" */),
  "component---src-pages-hello-js": () => import("./../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pilates-js": () => import("./../src/pages/pilates.js" /* webpackChunkName: "component---src-pages-pilates-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-vaginal-steaming-js": () => import("./../src/pages/vaginal-steaming.js" /* webpackChunkName: "component---src-pages-vaginal-steaming-js" */)
}

